import './App.css';

import React, { useContext } from 'react';

import { CliContext } from './context/CliContext';

const MyComponent = ({ wordsArray, inputString, byWhom }) => {
 
  const { userFontColor, badEncountered } = useContext(CliContext);

  const checkAndAddClass = (word) => {
    let wordFormatted = word.replace(/[^a-zA-Z0-9]+$/, '');
    const uppercaseWord = wordFormatted.toUpperCase();

    function addIndex(word, index = -3) {
      // Convert the word to uppercase

      let result = '';
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        let charCode = char.charCodeAt(0);

        // Check if the character is a letter
        if (charCode >= 65 && charCode <= 90) {
          // Cycle the character between A and Z
          charCode += index % 26;

          // Handle wrapping around the alphabet
          if (charCode > 90) {
            charCode -= 26;
          } else if (charCode < 65) {
            charCode += 26;
          }
        } else if (charCode >= 48 && charCode <= 57) {
          // Check if the character is a digit
          // Cycle the character between 0 and 9
          let num_index = index - 1
          charCode += num_index % 10;

          // Handle wrapping around the digits
          if (charCode > 57) {
            charCode -= 10;
          } else if (charCode < 48) {
            charCode += 10;
          }
        }

        // Convert the character code back to a character
        const modifiedChar = String.fromCharCode(charCode);

        // Add the modified character to the result string
        result += modifiedChar;
      }

      return result;
    }

    if (byWhom === "AI") {

      let matchingWordAI = wordsArray.find(item => item.word.toUpperCase() === addIndex(uppercaseWord));

      if(!matchingWordAI){

        let cleanUppercaseWord = uppercaseWord.replace(/[^a-zA-Z0-9\s]/g, '');

        matchingWordAI = wordsArray.find(item => item.word.toUpperCase().replace(/[^a-zA-Z0-9\s]/g, '') === addIndex(cleanUppercaseWord));
      }


      let plus3 = addIndex(word, -3)
      let minus3 = addIndex(word, 3)

    
      return matchingWordAI ? (badEncountered ? 'typewriter fontAI text-color-red' : 'typewriter fontAI') : (badEncountered ? 'typewriter text-color-red' : 'typewriter');
    }
    else {

      const matchingWord = wordsArray.find(item => item.word.toUpperCase() === uppercaseWord);

     

      if (matchingWord) {
        if (matchingWord.entity_type === 'PERSON') {
          return 'classPerson';
        } else if (matchingWord.entity_type === 'PHONE_NUMBER') {
          return 'classNumber';
        }
        else if (matchingWord.entity_type === 'EMAIL_ADDRESS') {
          return 'classEmail';
        }
        else if (matchingWord.entity_type === 'ALPHANUMSYM') {
          return 'classAlphaNumSym';
        }
        else {
          return 'underlineClass';
        }
      }
      else {
        return userFontColor;
      }
    }
    return '';
  };

  //comment out old code for applying class to words
  /*const applyClassToWords = (inputString) => {
    // const words = inputString.split(' ');
    const words = inputString.match(/(?:\S+|\n)/g);
    
    return words.map((word, index) => (
      <span key={index} className={checkAndAddClass(word)}>
        {word}{' '}
      </span>
    ));
  };*/

  const applyClassToWords = (inputString) => {
    const words = inputString.match(/(?:\S+|\n)/g);

    return words.map((word, index) => {
      // Replace newlines with <br> elements
      // const wordWithoutNewlines = word.replace(/\n/g, "<br />");

      if (word === "\n") {
        return <br key={index} />;
      }

      else {
        // Apply the class using checkAndAddClass and wrap in a <span>
        return (
          <span key={index} className={checkAndAddClass(word)}>
            {word}{' '}
          </span>
        );
      }


    });
  };

  return (
    <div>
      <p>{applyClassToWords(inputString)}</p>
    </div>
  );
};

export default MyComponent;
