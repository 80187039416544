import './App.css';

import React, { useState, useEffect, useRef, useContext } from 'react';
import ImageGallery from './ImageGallery';
import neo_path from "./neo.jpeg"
import axios from 'axios';
import { downloadImage } from './utilities/downloadImage';
import { commandToEmailConversation, commandToDownloadLatestImage, commandToDownloadAllImage, commandToShareFeedBack, commandToGiveFeedback, commandToChangeFontColor, commandToGiveColorOptions, commandsArray, commandsPrinted } from "./data/commands";
import { CliContext } from './context/CliContext';
import TypewriterEffect from './TypeWriterEffect';
import MyComponent from './MyComponent';



const CliComponent = () => {
  // Define a state variable to track the current dot count
  const [img, setImg] = useState([neo_path]);

  // whether gallery must be visible or not
  const [showGallery, setShowGallery] = useState(false);

  // Define a state variable to track the current dot count and action completion

  // true is input is disabled
  const [isInputDisabled, setInputDisabled] = useState(false);

  // Loading dots if Reply is being generated
  const [loadingDots, setLoadingDots] = useState('');

  const [outputValue, setOutputValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const [isTyping, setIsTyping] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const { setShowThumbnail, convoArray, setConvoArray, messageArray, setMessageArray, setThumbnailArr, badWords, setBadWords, badEncountered, setBadEncountered, userFontColor, setUserFontColor, setTypeSpeed } = useContext(CliContext);

  // This is the PII data array
  const [entityCheckedWord, setEntityCheckedWord] = useState([{ entity_type: "PERSON", word: "JOHN" }, { entity_type: "PERSON", word: "DOE" }, { entity_type: "LOCATION", word: "MARS" }, { entity_type: 'PHONE_NUMBER', word: '234-555-6678' }, { entity_type: 'EMAIL_ADDRESS', word: 'xyz@gmail.com' }, { entity_type: 'ID', word: '1234567890123' }]);
  const [checker, setChecker] = useState([]);
  // list of common words
  const [Words, setWords] = useState();


  // use effect to fetch suggested words and bad words
  useEffect(() => {
    // When the page loads do the following: 

    // fetch the text file that has the loading text
    fetchTextFile();
    // load the bad words file (used to color screen red when a bad word is entered)
    fetchBadWords();
    // download list of suggestions (common words)
    downloadSuggestions();

    // handleAIReplies();
    inputRef.current.focus();
  }, []);

  //use effect for ...
  useEffect(() => {

    // This is triggered when the isInputDisabled value changes
    let dotInterval;

    if (isInputDisabled) {
      // if the input is disabled show three dots and keey cycling between them
      dotInterval = setInterval(() => {
        setLoadingDots(prevDots => prevDots.length >= 3 ? '' : prevDots + '.');
      }, 500);
    } else {
      inputRef.current.focus();
      setLoadingDots('');
    }

    return () => {
      clearInterval(dotInterval);
    };

  }, [isInputDisabled]);


  // Sets up an effect to handle key presses and focus on the input element if certain conditions are met.
  // This is used to focus back to input element if any key is pressed
  // This part was added earlier I am not sure how this works, afaik the purpose is to bring the input element to focus if any key is pressed at the start of page load.
  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key, metaKey, ctrlKey } = event;

      // Exclude special commands like shortcuts
      if (metaKey || ctrlKey || key === 'Tab') {
        return;
      }

      // if the input is not disabled set the focs back to the input box
      if (!isInputDisabled) {
        inputRef.current.focus();
      }
    };

    // adds a consistent event listner for all key presses
    document.addEventListener("keydown", handleKeyPress);

    // Cleans up code by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };

  }, []);


  /**
 * @async
 * @function fetchTextFile
 * @description Fetches the content of a text file ('example.txt') using the Fetch API.
 * 
 * @throws {Error} Throws an error if there is an issue reading the file.
 * 
 * @returns {Promise<void>} Returns a promise that resolves once the file content is successfully fetched.
 */
  const fetchTextFile = async () => {
    try {
      // Fetch the content of the text file
      // Reading the .txt file
      const response = await fetch('example.txt');

      // Read the text from the response using .text()
      const text = await response.text();

      // Set the fetched text as the output value
      setOutputValue(text);

      // Set a timeout to simulate typing effect based on the length of the text
      setTimeout(() => {
        setIsTyping(false);
        // Uncomment the line below if you want to focus on an input reference after fetching
        // inputRef.current.focus();
      }, text.length);
    } catch (error) {
      // Handle errors by setting an error message in the output value
      setOutputValue(`Error reading file: ${error.message}`);
    }
  };


  /**
   * @async
   * @function downloadSuggestions
   * @description Downloads a list of English words from the specified URL and sets the words in the use state.
   * 
   * @throws {Error} Throws an error if there is an issue with the HTTP request or data processing.
   * 
   * @returns {Promise<void>} Returns a promise that resolves once the list of words is successfully retrieved and set in the state.
   */
  async function downloadSuggestions() {
    try {
      // Make an HTTP GET request to the URL with the list of words
      const response = await axios.get(
        'https://raw.githubusercontent.com/dwyl/english-words/master/words_alpha.txt'
      );

      // Split the received data into an array of words and set it in the state thereby storing them in the words array
      setWords(response.data.split('\n'));
    } catch (error) {
      // Handle errors by throwing an error with a descriptive message
      throw new Error(`Error downloading suggestions: ${error.message}`);
    }
  }


  /**
   * @async
   * @function fetchSuggestions
   * @description Fetches and filters a list of suggestions based on the provided input and minimum length.
   * 
   * @param {string} input - The input string for which suggestions are fetched.
   * @param {number} minLength - The minimum length a suggestion should have to be considered.
   * 
   * @throws {Error} Throws an error if there is an issue with filtering or setting suggestions.
   * 
   * @returns {Promise<void>} Returns a promise that resolves once the suggestions are successfully filtered and set.
   */
  const fetchSuggestions = async (input, minLength) => {
    try {
      // Filter the list of Words based on the provided conditions
      const filteredSuggestions = Words.filter(
        (suggestion) =>
          suggestion.toLowerCase().startsWith(input.toLowerCase()) &&
          suggestion.length > minLength &&
          suggestion.toLowerCase() !== input.toLowerCase() + '\r'
      );

      // Set the filtered suggestions in the state
      setSuggestions(filteredSuggestions);
    } catch (error) {
      // Handle errors by logging an error message
      console.error('Error fetching suggestions:', error);
    }
  };

  // This function is triggered when there is a change of state of the input box (user input box in which user types)
  // e here is the event
  /**
 * @function handleInputChange
 * @description Handles the input change event, updating various state variables based on the input value.
 * 
 * @param {Event} e - The input change event.
 * 
 * @returns {void} Returns nothing.
 */
  const handleInputChange = (e) => {
    // Working
    /**
     * Check if input > 0 chars
     * Fetch suggestions for a particular word (find words that will complete the current word)
     * if a bad word is found, setBadEncountered as true (this makes the text red temporarily)
     * if input = 0 chars then reset the suggestions array
     */


    // Extract the value from the input event
    // Value here is the input text
    const value = e.target.value;

    // Set the typing speed of the welcome neo text to zero so that it types everything instantly (please refer to typewriter file code for more details )
    setTypeSpeed(0);

    // Set the input value in the state
    setInputValue(value);

    // Check if the input value has a length greater than 0
    if (value.length > 0) {
      // Split the input value into an array of words
      const words = value.split(' ');

      // Get the last word from the array
      const lastWord = words[words.length - 1];

      // Fetch suggestions for the last word with a minimum length of 2
      fetchSuggestions(lastWord, 2);

      // Check if any bad word is encountered in the input
      setBadEncountered(badWords.find((bw) => words.includes(bw)));
    } else {
      // Reset suggestions if the input value is empty (this shows the grey suggestions)
      setSuggestions([]);
    }
  };

  /**
   * @function handleAutocomplete
   * @description Handles autocomplete functionality by replacing the last word in the input with a suggestion.
   * 
   * @param {string} suggestion - The suggested word to replace the last word in the input.
   * 
   * @returns {void} Returns nothing.
   */
  // I haven't written this function I dont fully understand how it works, I havent modified it ever yet
  const handleAutocomplete = (suggestion) => {
    // Split the input value into an array of words
    const words = inputValue.split(' ');

    // Get the index of the last word in the array
    const lastWordIndex = words.length - 1;

    // Create a copy of the array
    const updatedWords = [...words];

    // Replace the last word with the suggested word
    updatedWords[lastWordIndex] = suggestion;

    // Update the input value with the modified array of words
    setInputValue(updatedWords.join(' '));

    // Clear the suggestions array
    setSuggestions([]);
  };


  /**
   * @async
   * @function fetchBadWords
   * @description Fetches a list of potentially offensive words from a specified URL and sets them in the state.
   * 
   * @throws {Error} Throws an error if there is an issue with the HTTP request or data processing.
   * 
   * @returns {Promise<void>} Returns a promise that resolves once the list of bad words is successfully retrieved and set in the state.
   */
  const fetchBadWords = async () => {
    try {
      // Make an HTTP GET request to the specified URL
      const response = await axios.get(
        'https://raw.githubusercontent.com/LDNOOBW/List-of-Dirty-Naughty-Obscene-and-Otherwise-Bad-Words/master/en'
      );

      // Split the received data into an array of bad words and set it in the state
      setBadWords(response.data.split('\n'));
    } catch (error) {
      // Handle errors by logging an error message
      console.error('Error fetching bad words:', error);
    }
  };


  /**
   * @function containsAlphabetNumberAndSymbol
   * @description Checks if the input string contains at least one alphabet character, one numeric character, and one special symbol.
   * 
   * @param {string} input - The input string to be checked.
   * 
   * @returns {boolean} Returns true if the input meets the specified criteria, otherwise false.
   */
  function containsAlphabetNumberAndSymbol(input) {
    // List of regular expressions for alphabet and number combinations, alphabet and symbol combinations, and number and symbol combinations
    const regexList = [
      /^(?=.*[A-Za-z])(?=.*[0-9]).*$/,
      /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).*$/,
      /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).*$/
    ];

    // Combine the individual regular expressions into a single regular expression using alternation (|)
    const combinedRegex = new RegExp(regexList.map(regex => regex.source).join('|'));

    // Test if the input satisfies the combined regular expression and has a length greater than 3
    return combinedRegex.test(input) && input.length > 3;
  }


  /**
   * @function addIndex
   * @description Converts a given word to uppercase and returns it.
   * 
   * @param {string} word - The word to be converted to uppercase.
   * @param {number} index - Unused parameter (optional index argument).
   * 
   * @returns {string} Returns the uppercase version of the input word.
   */
  // this function was earlier used to add and cycle characters now we just convert that word to uppercase
  function addIndex(word, index) {
    // Convert the word to uppercase
    const uppercaseWord = word.toUpperCase();

    // Return the uppercase word
    return uppercaseWord;
  }

  /**
 * @async
 * @function handleAIReplies1
 * @description Handles user input and makes an async request to the "/ask" function for generating an AI reply
 * 
 * @param {string} userInput - The user input text.
 * @param {boolean} first - Indicates whether it is the first user input (optional, default is false).
 * 
 * @returns {Promise<string>} Returns a promise that resolves to the AI-generated answer or an error message.
 */
  async function handleAIReplies1(userInput, first = false) {
    // the user input text = handyInput
    let handyInput = userInput

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Create a JSON with query : Primary Query
    // messages : The history Array
    // Set welcome: first, IF this is the first message, it will be Handled Accordingly
    let raw = JSON.stringify({ "query": handyInput, "messages": messageArray, "welcome": first });


    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    let url = "/ask"

    try {
      let response = await fetch(url, requestOptions)
      let status = response.status


      if (status === 200) {
        let result = await response.json()

        // Extract the result / answer
        let answer = result.answer

        // if the answer starts with image, we add it to the images array as an image
        if (answer.startsWith("data:image")) {
          let new_image = answer
          setImg(prevImgs => [...prevImgs, new_image])

          // show the gallery to the user after adding image
          setShowGallery(true);
          answer = "Image successfully generated check gallery!"

        }

        // Add the data to the response array
        const convoData = {
          by: 'AI',
          timestamp: new Date().toLocaleString(),
          content: answer,
        };

        // add the message to the existing array as a new message
        setConvoArray((prevArray) => [...prevArray, convoData]);

        //add to message aray as well (this is the array with masked/ cycled  input messages)
        setMessageArray((prevMsgArray) => [...prevMsgArray, convoData]);

        return answer

      }

      else {
        let result = await response.json()

        const convoData = {
          by: 'AI',
          timestamp: new Date().toLocaleString(),
          content: result.message,
        };
        setConvoArray((prevArray) => [...prevArray, convoData]);
      }


    }
    catch (error) {
      console.error("error while reaching out to the server:", error)
    }


  }
  /**
   * @async
   * @function handleEntityChecker1
   * @description Handles the detection of personally identifiable information (PII) data in a given input using a specified endpoint.
   * 
   * @param {string} checkInput - The input string to be checked for PII data.
   * @param {string} url - The URL of the endpoint for PII data detection.
   * @param {string} highlight - Indicates whether to highlight identified entities ("on" by default).
   * 
   * @returns {Promise<string>} Returns a promise that resolves to the final string after PII data detection and handling.
   */
  async function handleEntityChecker1(checkInput, url, highlight = "on") {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "query": checkInput.toString(),
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    checkInput = checkInput.toLowerCase()

    try {
      let final_string = checkInput
      let response = await fetch(url, requestOptions)
      if (!response.ok) {
        let error = await response.text();
        final_string = await checkFallback(checkInput)
        return final_string
      }

      let result = await response.json()


      final_string = result.answer;
      let count = result.count;

      if (count > 0) {

        let data = result.identified

        let updatedEntityCheckedWord = [...entityCheckedWord];
        if (highlight === "on") {
          // Add the words into the PII data array so that they get detected and highlighted

          // Split multi-word strings into single-word strings
          data = data.flatMap(word => word.split(' '));

          data.forEach(word => {
            let current_entity_type = "PERSONAL"
            let current_word = word

            // Add new words to the array
            if (!Words.includes(current_word)) {
              updatedEntityCheckedWord.push({ entity_type: current_entity_type, word: current_word.toUpperCase() })
            }
          })
          // Update the EntityCheckedWord array with the new list of words
          setEntityCheckedWord(updatedEntityCheckedWord)

        }


      }

      else {
        console.log("")

      }

      return final_string
    }
    catch {

      // const updatedEntityCheckedWord = [...entityCheckedWord];
      // updatedEntityCheckedWord.push({ entity_type: "NAME", word: "string" })
      // setEntityCheckedWord(updatedEntityCheckedWord)
      // if any error happens use the Fallback method (not really reliable it uses regex)
      let final_string = await checkFallback(checkInput)

      return final_string
    }

  }

  /**
   * @async
   * @function checkFallback
   * @description Handles the fallback mechanism for PII data detection when the primary detection method fails.
   * 
   * @param {string} checkInput - The input string to be checked in case of primary detection failure.
   * 
   * @returns {Promise<string>} Returns a promise that resolves to the final string after fallback handling.
   */
  async function checkFallback(checkInput) {
    // Process each word in the input string
    const final_string = checkInput.split(" ").map((el) => {
      // Define a regular expression for special characters
      const specialChars = /[!,.?]/;

      // Copy the word, excluding the last special character if present
      let elcopy = specialChars.test(el[el.length - 1]) ? el.slice(0, -1) : el;

      // Check if Words array is available and non-empty
      if (Words && Array.isArray(Words) && Words.length > 0) {
        // Check if the lowercase version of the word is not in Words array and the word consists only of letters
        if (
          !Words.includes(`${elcopy.toLowerCase()}\r`) &&
          /^[A-Za-z]+$/.test(elcopy)
        ) {
          // Update the entityCheckedWord array with identified personal information
          setEntityCheckedWord((prevEntityCheckedWord) => [
            ...prevEntityCheckedWord,
            { entity_type: "PERSON", word: elcopy },
          ]);

          // Return the word after adding an index
          return addIndex(el, 3);
        }
        // Check if the word is a number and has a length greater than 4
        else if (!isNaN(elcopy) && elcopy.length > 4) {
          // Update the entityCheckedWord array with identified phone numbers
          setEntityCheckedWord((prevEntityCheckedWord) => [
            ...prevEntityCheckedWord,
            { entity_type: "PHONE_NUMBER", word: el },
          ]);

          // Return the word after adding an index
          return addIndex(el, 3);
        }
        // Check if the word contains alphabets, numbers, and symbols
        else if (containsAlphabetNumberAndSymbol(elcopy)) {
          // Update the entityCheckedWord array with identified alphanumeric symbols
          setEntityCheckedWord((prevEntityCheckedWord) => [
            ...prevEntityCheckedWord,
            { entity_type: "ALPHANUMSYM", word: el.toUpperCase() },
          ]);

          // Return the word after adding an index
          return addIndex(el, 3);
        }
      }

      // Return the original word if no identification is made
      return el;
    }).join(" ");

    // Return the final processed string
    return final_string;
  }


  // This is the main function Responsible for handling the key down behaviour
  // e here is the event
  const handleKeyDown = async (e) => {
    // if the input key is tab compelte the suggestion
    if (e.key === 'Tab' && suggestions.length > 0) {
      e.preventDefault();
      handleAutocomplete(suggestions[0]);
    }

    if (e.key === 'Enter' && inputValue.length > 0) {
      // if the key is enter and the input text exists consider is as the user input\
      // the first step is always adding the input to the messages array
      const convoData = {
        by: 'user',
        timestamp: new Date().toLocaleString(),
        content: inputValue,
      };
      setConvoArray((prevArray) => [...prevArray, convoData]);

      // Handles the DEMO Flow
      // if the convo length is smaller than 4 (less than = 4 messages in the array this means we can check for the demo or help keywords)
      if (convoArray.length <= 4) {
        let first_input_text = inputValue.replace(/[^a-zA-Z]/g, '').toLowerCase();


        setInputValue('');
        setInputDisabled(true); // Disable the input element

        // if the input text is help or demo start the demo flow
        if (first_input_text === "help" || first_input_text === "demo") {

          // add the demo text and the demo follow up text to the array
          let help_response = "I can protect PII data in conversations between you and LLMs, You can say something like:"

          let convoData = {
            by: 'AI',
            timestamp: new Date().toLocaleString(),
            content: help_response,
          };
          setConvoArray((prevArray) => [...prevArray, convoData]);


          let help_followup = `"Hi my name is Mrkq Grh and I live on Pduv! My phone number is 678-999-0012"\n\nCopy the above text and see what happens, your turn now!`

          let helpFollowupData = {
            by: 'AI',
            timestamp: new Date().toLocaleString(),
            content: help_followup,
          };

          // add both the statements to the converstaion array
          setConvoArray((prevArray) => [...prevArray, helpFollowupData]);
          setInputDisabled(false); // enable the input element
          return undefined
        }

        // Write case for when the user is inside the help flow
        else if (convoArray[convoArray.length - 3] && convoArray[convoArray.length - 3].by === "user" && convoArray[convoArray.length - 3].content === "help") {

          // find the final masked string
          let updated_string = await handleEntityChecker1(inputValue, "https://m2m2.mllabs.dev");

          // add to new array
          const messageData = {
            by: 'user',
            timestamp: new Date().toLocaleString(),
            content: updated_string,
          };

          // add the PII masked data to the messageArray (this array is used to store the masked messages) 
          // Note this is different from the CONVO ARRAY which is used to display messages on the screen
          // the messageArray is used as history, not for display.
          setMessageArray((prevMsgArray) => [...prevMsgArray, messageData]);


          // find the reply to the user question using the handleAIReplies function
          let reply = await handleAIReplies1(updated_string, true);

          setInputDisabled(false); // enable the input element

          return

        }

        inputRef.current.focus();

      }

      // If this is a normal message
      // implying that its not in the commandsArray or the commandsPrinted array, handle it noramlly -> 
      if (!commandsArray.includes(inputValue.toLowerCase().trim()) && !commandsPrinted.includes(inputValue)) {

        //set input to null
        setInputValue('');
        setInputDisabled(true); // Disable the input element

        let updated_string = await handleEntityChecker1(inputValue, "https://m2m2.mllabs.dev");

        const messageData = {
          by: 'user',
          timestamp: new Date().toLocaleString(),
          content: updated_string,
        };

        // add the PII masked data to the messageArray (this array is used to store the masked messages) 
        // Note this is different from the CONVO ARRAY which is used to display messages on the screen
        // the messageArray is used as history, not for display.
        setMessageArray((prevMsgArray) => [...prevMsgArray, messageData])
        let rep = await handleAIReplies1(updated_string);

        setInputDisabled(false); // enable the input element

      }
      // after this process is completed bring the input back to focus
      inputRef.current.focus();

      // if the command is to download the last image do the same accordingly
      const lowerCaseInput = inputValue.toLowerCase().trim();
      if (lowerCaseInput === commandToDownloadLatestImage) {
        const lastImage = img[img.length - 1];
        downloadImage(lastImage);
        let timeoutId2;

        setShowThumbnail(true);
        setThumbnailArr([img[img.length - 1]]);

        timeoutId2 = setTimeout(() => {
          setShowThumbnail(false);
        }, 5000);

        return () => {
          clearTimeout(timeoutId2);
        };
      }
      // command to download all images handle accordingly
      else if (lowerCaseInput === commandToDownloadAllImage) {
        setShowThumbnail(true);
        img.forEach((image, index) => {
          setTimeout(() => {
            downloadImage(image);
            setThumbnailArr([image]);
            if (index === img.length - 1) {


              setTimeout(() => {
                setShowThumbnail(false);
              }, 1000);
            }
          }, index * 1000);
        });
      }
      // command to change font color just add as input and ask the user to choose colors
      else if (lowerCaseInput === commandToChangeFontColor) {
        const convoData = {
          by: 'AI',
          timestamp: new Date().toLocaleString(),
          content: commandToGiveColorOptions,
        };
        setConvoArray((prevArray) => [...prevArray, convoData]);
      }
    }

    setInputDisabled(false); // enable the input element at the end in all cases
  };


  /**
   * @function getSuggestionText
   * @description Generates suggestion text based on the current input and available suggestions.
   * 
   * @returns {React.ReactNode|null} Returns React node representing the suggestion text or null if no suggestion is available.
   */
  const getSuggestionText = () => {
    // Check if there are suggestions and the input value is not empty
    if (suggestions.length > 0 && inputValue.length > 0) {
      // Retrieve the first suggestion and split the input into words
      const suggestion = suggestions[0];
      const words = inputValue.trim().split(" ");

      // Get the last word and its length
      const lastWord = words[words.length - 1];
      const lastWordLength = lastWord.length;

      // Calculate the remaining part of the suggestion
      const remainingPart = suggestion.substring(lastWordLength);

      // Return a React fragment with the suggestion text
      return (
        <>
          <span style={{ opacity: 0 }}>{inputValue}</span>
          {remainingPart}
        </>
      );
    }

    // Return null if no suggestion is available
    return null;
  };



  const suggestionText = getSuggestionText();

  // The below component renders the actual page

  return (
    <div className="cli-container">
      <div className="cli-output">
        {/* Render the Text value from the file  using TypewriterEffect (Text that appears on top)*/}
        <TypewriterEffect text={outputValue} />
        {/* Show the conversation array on the page with the following conditions */}
        {convoArray.map((convo, index) =>
          // if the item is a command to email conversation show a link to do the same
          convo.content.toLowerCase().trim() === commandToEmailConversation ? (
            <a
              href={`https://mail.google.com/?view=cm&su=${encodeURIComponent(
                new Date().toLocaleString()
              )}&body=${encodeURIComponent(
                convoArray.map((convo) => `${convo.by}: ${convo.content}`).join('\n')
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              {convo.content}
            </a>
            // if the message is actually the reply to share feedback then make the text red in color and show the URL to send a new email
          ) : convo.content === commandToShareFeedBack ? (
            <p className={convo.by === "AI" ? badEncountered ? 'typewriter text-color-red' : 'typewriter' : null}>
              Do you want to&nbsp;
              <a
                href={`https://mail.google.com/?view=cm&fs=1&su=${encodeURIComponent(
                  new Date().toLocaleString()
                )}&body=${encodeURIComponent(
                  convoArray.map((convo) => `${convo.by}: ${convo.content}`).join('\n')
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {commandToEmailConversation}
              </a> or share&nbsp;
              <a
                href={`https://mail.google.com/?view=cm&fs=1&to=developers@shield.live&su=${encodeURIComponent(
                  new Date().toLocaleString()
                )}&body=${encodeURIComponent(
                  convoArray.map((convo) => `${convo.by}: ${convo.content}`).join('\n')
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {commandToGiveFeedback}
              </a> with the developers
            </p>
            // if the command is to show the option to change font color then show a list of buttons that can be used to set the user font color accordingly 
          ) : convo.content === commandToGiveColorOptions ? (<p className={badEncountered ? 'typewriter text-color-red' : 'typewriter'}>
            Please select color <p className='color-options-container'><span className='color-option color-option-red' onClick={() => setUserFontColor("color-option-red")}>red</span> <span className='color-option color-option-yellow' onClick={() => setUserFontColor("color-option-yellow")}>yellow</span> <span className='color-option color-option-purple' onClick={() => setUserFontColor("color-option-purple")}>purple</span></p>
          </p>) : (convo.content.split(" ").reduce((acc, curr) => entityCheckedWord.includes(curr) ? acc.push(curr) : acc).length > 0 && !commandsArray.includes(convo.content.toLowerCase().trim()) && !commandsPrinted.includes(convo.content)) ?
            // if there are no commands involved use the MyComponent component to show the message
            /**
              * 
              * Covers the following cases:
              * - Check if any bad word exists, if yes color it red
              * - Check if it's a command to download the image or change font color
              * 
              * If the conversation content matches a command to download the image or change font color,
              * render a special component (MyComponent) with specific props (wordsArray, inputString, byWhom) if its a normal message
              * Otherwise, render a paragraph element with the respective command details, no need to render command replies or messages using the MyComponent component because these are just normal text elements.
              * 
              * @returns {React.ReactNode} Returns the rendered component or paragraph based on the conditions.
              */
            (<MyComponent wordsArray={entityCheckedWord} inputString={convo.content} byWhom={convo.by} />) : (

              <p key={index} className={convo.by === "AI" ? badEncountered ? 'typewriter text-color-red' : 'typewriter' : (convo.content.toLowerCase().trim() === commandToDownloadAllImage || convo.content.toLowerCase().trim() === commandToDownloadLatestImage || convo.content.toLowerCase().trim() === commandToChangeFontColor) ? "link-color" : userFontColor}>{convo.content}</p>
            )
        )}
      </div>
      <div className="cli-input">
          {/* Conditional rendering of the blinking cursor or loading dots based on input state */}
        {!isInputDisabled ? (
          <span className={inputValue.trim().length > 0 ? 'blinker-none' : 'blinker'}></span>
        ) : (
          <span id='loading-dots'>{loadingDots}</span>
        )}
        {/* Input field for user interaction */}
        <input
          ref={inputRef}
          type="text"
          className="cli-input-field"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={async (event) => await handleKeyDown(event)}
          disabled={isInputDisabled}
          style={{
            caretColor: inputValue.length > 0 ? 'currentColor' : 'transparent',
            cursor: isInputDisabled ? 'wait' : 'pointer',
          }}
        />
         {/* Display suggestion text if available */}
        {suggestionText && (
          <span
            id='suggestion-text'
          >
            {suggestionText}
          </span>
        )}
      </div>
       {/* ImageGallery component for displaying images */}
      <ImageGallery img={img} showGallery={showGallery} setShowGallery={setShowGallery} />
    </div>
  );
};

export default CliComponent;
