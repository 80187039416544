export const commandToEmailConversation = "email this conversation";

export const commandToGiveFeedback = "feedback";

export const commandToDownloadLatestImage = "download";

export const commandToDownloadAllImage = "download all";

export const commandToShareFeedBack = "Do you want to email this conversation or share feedback with the developers?";

export const commandToChangeFontColor = "change font color";

export const commandToGiveColorOptions = "Please select color red yellow purple";

export const commandsArray = ["email this conversation","download","download all","change font color"];

export const commandsPrinted = ["Do you want to email this conversation or share feedback with the developers?","Please select color red yellow purple"];
