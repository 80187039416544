import React, { useState, useEffect, useContext } from 'react';
// import { img } from './data/img';
import { HiMail } from "react-icons/hi";
import mona from "./mona-lisa.png";
import { CliContext } from './context/CliContext';
import { commandToShareFeedBack } from './data/commands';

const ImageGallery = ({ img, showGallery, setShowGallery }) => {
  const { showThumbnail, setShowThumbnail, setConvoArray, thumbnailArr } = useContext(CliContext);
  const [showButtons, setShowButtons] = useState(false);
  const [showGalleryHover, setShowGalleryHover] = useState(false);

  useEffect(() => {
    let timeoutId;
    let timeoutId2;

    setShowThumbnail(true);

    timeoutId = setTimeout(() => {
      setShowThumbnail(false);
    }, 5000);

    timeoutId2 = setTimeout(() => {
      setShowButtons(false);
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId2);
    };
  }, [img]);

  const toggleGallery = () => {
    setShowGallery(prevState => !prevState);
  };

  const handleEmailFeedback = () => {
    const convoData = {
      by: 'AI',
      timestamp: new Date().toLocaleString(),
      content: commandToShareFeedBack,
    };
    setConvoArray((prevArray) => [...prevArray, convoData]);
  }

  function getImageUrl(image) {
    if (isBase64(image)) {
      return createDataUrl(image);
    } else {
      return image;
    }
  }
  
  function isBase64(image) {
    return image.startsWith("data:image");
  }
  
  function createDataUrl(base64Image) {
    const type = base64Image.split(";")[0].split(":")[1];
    return URL.createObjectURL(
      new Blob([base64ToUint8Array(base64Image)], { type })
    );
  }
  
  function base64ToUint8Array(base64Image) {
    const byteString = atob(base64Image.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    return uint8Array;
  }
  

  return (
    <div
      className="image-gallery" onMouseEnter={() => setShowGalleryHover(true)} 
    >
      {showGallery && showGalleryHover && (
        <div className="gallery-container">
          {img.map((i, index) => (
            <a href={getImageUrl(i)} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={i} alt="images" className="responsive-image" />
            </a>
          ))}
        </div>
      )}
      <div className="gallery-button">
        {showThumbnail && thumbnailArr.length > 0 && (
          <img src={thumbnailArr[0]} alt="thumbnail" />
        )}
        <div className={`bottom-icons ${showButtons ? 'show' : ''}`}>
          <img src={mona} alt="mona" className='mona-lisa' onClick={toggleGallery} />
          <HiMail className='mail' onClick={handleEmailFeedback} />
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
