import './App.css';
import FontPreloader from './FontPreloader';
import CliComponent from './terminalcli';
import logo from "./logo.png";



function App() {
  const today = new Date();

  const day = today.getDate();
  const month = today.getMonth() + 1; // Months are zero-based, so adding 1
  const year = today.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;


  return (
    <div className="App">
      {/* <FontPreloader/> */}
      <img src={logo} alt="logo" className='logo'/>
      <div>
          <span className="v2 easter-egg">IHR &nbsp;</span>
          <span className="v3 easter-egg">YHUVLRQ 5.6 &nbsp;</span>
          <span className="v1 easter-egg">{formattedDate}</span>
      </div>
      <CliComponent />
    </div>
  );
}

export default App;
