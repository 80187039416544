import './App.css';

import React, { useContext, useEffect, useState } from 'react';
import { CliContext } from './context/CliContext';

const TypewriterEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [demoStatus,setdemoStatus] = useState(false)
  // New state variable
  const { badEncountered, typeSpeed, setTypeSpeed, typewriterCompleted, setTypewriterCompleted, convoArray, setConvoArray } = useContext(CliContext);

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCharIndex((prevIndex) => {
        if (prevIndex === text.length && text.length !==0) {
          // set typing as completed 
          setTypewriterCompleted(true)
          clearInterval(intervalId);
        }
        return prevIndex + 1;
      });
    }, typeSpeed);
    

    return () => {
      clearInterval(intervalId);
    };
  }, [text, typeSpeed]);

  useEffect(() => {
    setDisplayText(text.substring(0, currentCharIndex));
  }, [currentCharIndex, text]);


  useEffect(() => {
    if(typewriterCompleted && demoStatus == false && convoArray.length <3){
      
      const convoData = {
        by: 'AI',
        timestamp: new Date().toLocaleString(),
        content: "Hi! type 'demo' for showcasing my capabilities!"
      };

      setConvoArray((prevArray) => [...prevArray, convoData]);
      setdemoStatus(true)

    }
    
  }, [typewriterCompleted]);




  return (
    <div>
    <div className={`typewriter-container ${badEncountered ? 'text-color-red' : ''}`}>
      {displayText.split('').map((char, index) => (
        <span key={index} className="change">
          {char}
        </span>
      ))}
    </div>
     <br/><br/>
     </div>
  );



};

export default TypewriterEffect;
