import { createContext, useState } from "react";

export const CliContext = createContext();

export function CliProvider({ children }) {
    const [showThumbnail, setShowThumbnail] = useState(false);
    const [convoArray, setConvoArray] = useState([]);
    const [messageArray, setMessageArray] = useState([]);
    const [thumbnailArr, setThumbnailArr] = useState([]);
    const [badWords, setBadWords] = useState([]);
    const [badEncountered, setBadEncountered] = useState(false);
    const [userFontColor, setUserFontColor] = useState("#ffffff");
    const [typeSpeed, setTypeSpeed] = useState(80);
    const [typewriterCompleted, setTypewriterCompleted] = useState(false); 

    return (
        <CliContext.Provider value={{ showThumbnail, setShowThumbnail, convoArray, setConvoArray, messageArray, setMessageArray ,thumbnailArr, setThumbnailArr, badWords, setBadWords, badEncountered, setBadEncountered, userFontColor, setUserFontColor,typeSpeed ,setTypeSpeed, typewriterCompleted, setTypewriterCompleted}}>
            {children}
        </CliContext.Provider>
    )
}